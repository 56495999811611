import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import '@lottiefiles/lottie-player'

createApp(App).use(router).use(router).mount('#app')

const hamburger = document.querySelector('#hamburger-menu');
const sidebar = document.querySelector('#sidebar');
const header = document.querySelector('#header');

hamburger.addEventListener('click', () => {
    if (sidebar.classList.contains('hidden')){
        sidebar.classList.remove('hidden');        
        header.classList.add('h-full');
        
    } else {
        sidebar.classList.add('hidden');        
        header.classList.remove('h-full');
    }
})
