import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',       
    name: 'Home', 
    component: Home,
    meta: {
      title: 'Qualon - Innovating Smart Lifestyles'
    }
  },
  {
    path: '/about',    
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'About - Innovating Smart Lifestyles'
    }
  },
  {
    path: '/services',
    name: 'Services',
    // route level code-splitting
    // this generates a separate chunk (services.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue'),
    meta: {
      title: 'Services - Innovating Smart Lifestyles'
    }
  },
  {
    path: '/blog',  
    name: 'Blog',
    // route level code-splitting
    // this generates a separate chunk (blog.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
    meta: {
      title: 'Blog - Innovating Smart Lifestyles'
    }
  },
  {
    path: '/contact',    
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue'),
    meta: {
      title: 'Contact - Innovating Smart Lifestyles'
    }
  },
  {
    path: '/privacy-policy',    
    name: 'Privacy Policy',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/privacy-policy.vue'),
    meta: {
      title: 'Privacy Policy - Innovating Smart Lifestyles'
    }
  },
  {
    path: '/terms-and-conditions',    
    name: 'Terms & Conditions',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "terms-and-conditions" */ '../views/terms-and-conditions.vue'),
    meta: {
      title: 'Terms & Conditions - Innovating Smart Lifestyles'
    }
  },
  {
    path: '/:pathMatch(.*)*',    
    name: 'NotFound',
    // route level code-splitting
    // this generates a separate chunk (notfound.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "notfound" */ '../views/404.vue'),
    meta: {
      title: 'Not Found - Innovating Smart Lifestyles'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  if(!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    });

    tag.setAttribute('data-vue-router-controlled', '')

    return tag;
  })
  .forEach(tag => document.head.appendChild(tag))

  next()
})


export default router
