<template>
  <header id="header">
    <div class="navbar glass">
      <div class="logo">
        <router-link to="/" onclick="window.scrollTo(0, 0);"
          ><img src="./assets/images/logo_2x.png" alt="Qualon Logo"
        /></router-link>
      </div>
      <ul>
        <li class="nav-item">
          <router-link to="/" onclick="window.scrollTo(0, 0);"
            >Home</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/about" onclick="window.scrollTo(0, 0);"
            >About</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/services" onclick="window.scrollTo(0, 0);"
            >Services</router-link
          >
        </li>
        <li class="nav-item">
          <a
            href="https://blogs.qualon.xyz"
            target="_blank"
            rel="noopener"
            onclick="window.scrollTo(0, 0);"
            >Blog</a
          >
        </li>
        <li class="nav-item">
          <a
            href="https://portfolio.qualon.xyz"
            target="_blank"
            rel="noopener"
            onclick="window.scrollTo(0, 0);"
            >Portfolio</a
          >
        </li>

        <hr class="hidden 2xl:flex" />

        <div class="social hidden 2xl:flex">
          <ul>
            <li class="social-item">
              <a
                href="https://behance.net/qualon"
                rel="noopener"
                target="_blank"
                ><img
                  src="./assets/images/behance.svg"
                  alt="Behance"
                  class="w-8"
              /></a>
            </li>
            <li class="social-item">
              <a
                href="https://dribbble.com/qualon"
                rel="noopener"
                target="_blank"
                ><img
                  src="./assets/images/dribbble.svg"
                  alt="Dribbble"
                  class="w-7"
              /></a>
            </li>
            <li class="social-item">
              <a
                href="https://www.facebook.com/Qualon"
                rel="noopener"
                target="_blank"
                ><img
                  src="./assets/images/facebook.svg"
                  alt="Facebook"
                  class="w-3"
              /></a>
            </li>
            <li class="social-item">
              <a
                href="https://instagram.com/qualon.jpg"
                rel="noopener"
                target="_blank"
                ><img
                  src="./assets/images/instagram.svg"
                  alt="Instagram"
                  class="w-7"
              /></a>
            </li>
            <li class="social-item">
              <a
                href="https://www.youtube.com/qualon"
                rel="noopener"
                target="_blank"
                ><img
                  src="./assets/images/youtube.svg"
                  alt="Youtube"
                  class="w-7"
              /></a>
            </li>
            <li class="social-item">
              <a
                href="https://api.whatsapp.com/send?phone=94711549549"
                rel="noopener"
                target="_blank"
                ><img
                  src="./assets/images/whatsapp.svg"
                  alt="WhatsApp"
                  class="w-7"
              /></a>
            </li>
          </ul>
        </div>

        <li>
          <router-link to="/contact" onclick="window.scrollTo(0, 0);"
            ><button>Contact us</button></router-link
          >
        </li>
      </ul>
      <div class="hamburger-menu">
        <div
          onclick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))"
          aria-label="Main Menu"
          id="hamburger-menu"
        >
          <svg class="w-12" viewBox="0 0 100 100">
            <path
              class="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path class="line line2" d="M 20,50 H 80" />
            <path
              class="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </div>
      </div>
    </div>

    <div
      id="sidebar"
      class="
        hidden
        items-center
        h-screen
        glass
        absolute
        top-22
        right-0
        w-screen
        md:w-1/2
        lg:w-1/3
        xl:w-1/4
        animate__animated animate__fadeIn animate__slower
      "
    >
      <div class="">
        <ul id="sidemenu" class="animate__animated animate__slideInRight">
          <div class="sidebar-nav">
            <li>
              <router-link to="/"
                ><a href="#" class="menueffect" onclick="window.scrollTo(0, 0);"
                  >Home</a
                ></router-link
              >
            </li>
            <li>
              <router-link to="/about"
                ><a href="#" class="menueffect" onclick="window.scrollTo(0, 0);"
                  >About</a
                ></router-link
              >
            </li>
            <li>
              <router-link to="/services"
                ><a href="#" class="menueffect" onclick="window.scrollTo(0, 0);"
                  >Services</a
                ></router-link
              >
            </li>
            <li>
              <a
                href="https://blogs.qualon.xyz"
                target="_blank"
                rel="noopener"
                class="menueffect"
                onclick="window.scrollTo(0, 0);"
                >Blog</a
              >
            </li>
            <li>
              <a
                href="https://portfolio.qualon.xyz"
                target="_blank"
                rel="noopener"
                class="menueffect"
                onclick="window.scrollTo(0, 0);"
                >Portfolio</a
              >
            </li>
            <li>
              <router-link to="/contact"
                ><a href="#" class="menueffect" onclick="window.scrollTo(0, 0);"
                  >Contact us</a
                ></router-link
              >
            </li>
          </div>
          <div class="justify-center grid">
            <li class="social-btn">
              <span>Get in Touch with US!</span>
            </li>
            <div class="flex justify-evenly items-center">
              <li class="social-item px-3">
                <a
                  href="https://behance.net/qualon"
                  rel="noopener"
                  target="_blank"
                  ><img
                    src="./assets/images/behance.svg"
                    alt="Behance"
                    class="w-8"
                /></a>
              </li>
              <li class="social-item">
                <a
                  href="https://dribbble.com/qualon"
                  rel="noopener"
                  target="_blank"
                  ><img
                    src="./assets/images/dribbble.svg"
                    alt="Dribbble"
                    class="w-7"
                /></a>
              </li>
              <li class="social-item">
                <a
                  href="https://www.facebook.com/Qualon"
                  rel="noopener"
                  target="_blank"
                  ><img
                    src="./assets/images/facebook.svg"
                    alt="Facebook"
                    class="w-3"
                /></a>
              </li>
              <li class="social-item">
                <a
                  href="https://instagram.com/qualon.jpg"
                  rel="noopener"
                  target="_blank"
                  ><img
                    src="./assets/images/instagram.svg"
                    alt="Instagram"
                    class="w-7"
                /></a>
              </li>
              <li class="social-item">
                <a
                  href="https://www.youtube.com/qualon"
                  rel="noopener"
                  target="_blank"
                  ><img
                    src="./assets/images/youtube.svg"
                    alt="Youtube"
                    class="w-7"
                /></a>
              </li>
              <li class="social-item">
                <a
                  href="https://api.whatsapp.com/send?phone=94711549549"
                  rel="noopener"
                  target="_blank"
                  ><img
                    src="./assets/images/whatsapp.svg"
                    alt="WhatsApp"
                    class="w-7"
                /></a>
              </li>
            </div>
          </div>
        </ul>
      </div>
    </div>

    <div class="fixed bottom-0 w-full p-2 glass z-50 shadow-2xl lg:hidden">
      <ul class="flex justify-evenly">
        <li>
          <router-link to="/services"
            ><a href="#" class="menueffect" onclick="window.scrollTo(0, 0);"
              ><img
                class="w-7 m-auto"
                src="./assets/images/services.svg"
                alt=""
              /><span class="text-sm font-medium uppercase">Services</span></a
            ></router-link
          >
        </li>
        <li>
          <a
            href="https://portfolio.qualon.xyz"
            target="_blank"
            rel="noopener"
            class="menueffect"
            onclick="window.scrollTo(0, 0);"
            ><img
              class="w-7 m-auto"
              src="./assets/images/portfolio.svg"
              alt=""
            /><span class="text-sm font-medium uppercase">Portfolio</span></a
          >
        </li>
        <li>
          <router-link to="/contact"
            ><a href="#" class="menueffect" onclick="window.scrollTo(0, 0);"
              ><img
                class="w-7 m-auto"
                src="./assets/images/contact.svg"
                alt=""
              /><span class="text-sm font-medium uppercase">Contact</span></a
            ></router-link
          >
        </li>
      </ul>
    </div>
  </header>
  <router-view />
  <!-- Text Banner: Carrers -->
  <div class="clouds pt-10">
    <div></div>
  </div>
  <footer>
    <div class="footer pb-20 lg:pb-10">
      <div class="grid grid-cols-12 justify-center xl:items-center">
        <div class="copyrights">
          <span class=""
            >© {{ new Date().getFullYear() }} Qualon. All rights reserved.</span
          >
        </div>
        <div class="extra">
          <router-link to="/privacy-policy"
            ><a href="#" class="menueffect" onclick="window.scrollTo(0, 0);"
              >Privacy Policy</a
            ></router-link
          >
          <router-link to="/terms-and-conditions"
            ><a href="#" class="menueffect" onclick="window.scrollTo(0, 0);"
              >Terms & Conditions</a
            ></router-link
          >
        </div>
        <ul class="footer-social">
          <li class="footer-social-item ml-0">
            <a href="https://behance.net/qualon" rel="noopener" target="_blank"
              ><img
                src="./assets/images/behance_gray.svg"
                alt="Behance"
                class="w-8"
            /></a>
          </li>
          <li class="footer-social-item">
            <a href="https://dribbble.com/qualon" rel="noopener" target="_blank"
              ><img
                src="./assets/images/dribbble_gray.svg"
                alt="Dribbble"
                class="w-7"
            /></a>
          </li>
          <li class="footer-social-item">
            <a
              href="https://www.facebook.com/Qualon"
              rel="noopener"
              target="_blank"
              ><img
                src="./assets/images/facebook_gray.svg"
                alt="Facebook"
                class="w-3"
            /></a>
          </li>
          <li class="footer-social-item">
            <a
              href="https://instagram.com/qualon.jpg"
              rel="noopener"
              target="_blank"
              ><img
                src="./assets/images/instagram_gray.svg"
                alt="Instagram"
                class="w-7"
            /></a>
          </li>
          <li class="footer-social-item">
            <a
              href="https://www.youtube.com/qualon"
              rel="noopener"
              target="_blank"
              ><img
                src="./assets/images/youtube_gray.svg"
                alt="Youtube"
                class="w-7"
            /></a>
          </li>
          <li class="footer-social-item">
            <a
              href="https://api.whatsapp.com/send?phone=94711549549"
              rel="noopener"
              target="_blank"
              ><img
                src="./assets/images/whatsapp_gray.svg"
                alt="WhatsApp"
                class="w-7"
            /></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
