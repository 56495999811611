<template>
  <main class="bg-white">        
        <div class="container m-auto grid lg:grid-cols-12 justify-center h-screen items-center pt-28 md:pt-28 lg:pt-20 animate__animated animate__fadeIn">
            <div class="lg:col-span-5 w-full">
                 <h1 class="text-3xl text-center lg:text-5xl font-semibold tracking-widest uppercase">We are Qualon</h1>
                 <p class=" font-medium text-gray-800 text-lg leading-6 pt-3 mb-10 px-5 text-center lg:text-left xl:pl-16">We dream, architect, design and develop. Driven by one relentless pursuit, to craft innovative solutions</p>                
                 <div class="block md:flex px-5 md:justify-evenly">
                    <div class="justify-center">
                        <div class="text-sm text-center font-medium flex justify-center">
                            <router-link to="/contact" onclick="window.scrollTo(0, 0);"><button class="btn-grad w-full block items-center justify-center uppercase rounded-2xl font-semibold text-white px-10 py-2.5 mb-2 hover:shadow-md tracking-widest">Contact us<svg class="w-6 ml-2 -mt-0.5 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg></button></router-link>                                                
                        </div>                                                                             
                    </div> 
                    <div class="justify-center">
                        <div class="text-sm text-center font-medium flex justify-center">
                            <a href="https://portfolio.qualon.xyz" target="_blank" rel="noopener" onclick="window.scrollTo(0, 0);"><button class="w-full rounded-2xl block uppercase font-semibold border-2 border-gray-300 px-10 p-3 md:ml-5 hover:shadow-md transition ease-linear duration-500 tracking-widest">Discover our Work</button></a>                                              
                        </div>                                                                             
                    </div>  
                    
                </div>
            </div>
            <div class="lg:col-span-7">        
                <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_8ywcoffy.json"  background="transparent"  speed="0.30" loop autoplay class="w-full animate__animated animate__fadeIn"></lottie-player>               
            </div>               
        </div>     
    <div class="bg-blue-50">
        <div class="container m-auto justify-center p-20">    
            <h1 class="text-center text-4xl font-semibold text-primary tracking-widest uppercase">Our Services</h1>                        
        </div>
        <div class="container m-auto grid md:grid-cols-12 lg:justify-evenly gap-10 items-center content-center">
            <div class="service">
                <img loading="lazy" src="../assets/images/website_design.svg" alt="" class="w-full">
                <h2 class="font-semibold text-xl m-3">Website Design</h2>                            
            </div>
            <div class="service">
                <img loading="lazy" src="../assets/images/website_development.svg" alt="" class="w-full">
                <h2 class="font-semibold text-xl m-3">Website Development</h2>                            
            </div>
            <div class="service">
                <img loading="lazy" src="../assets/images/ecommerce_platform.svg" alt="" class="w-full">
                <h2 class="font-semibold text-xl m-3">eCommerce Platform</h2>                            
            </div>
            <div class="service">
                <img loading="lazy" src="../assets/images/user_interface_design.svg" alt="" class="w-full">
                <h2 class="font-semibold text-xl m-3">User Interface Desgin</h2>                            
            </div>
            <div class="service">
                <img loading="lazy" src="../assets/images/web_application_development.svg" alt="" class="w-full">
                <h2 class="font-semibold text-xl m-3">Web Application Development</h2>                            
            </div>
            <div class="service">
                <img loading="lazy" src="../assets/images/user_interface_design.svg" alt="" class="w-full">
                <h2 class="font-semibold text-xl m-3">User Experience Optimization</h2>                            
            </div> 
            <div class="hidden lg:block lg:col-span-2 xl:hidden"></div>           
            <div class="service">                
                <img loading="lazy" src="../assets/images/search_engine_optimization.svg" alt="" class="w-full">
                <h2 class="font-semibold text-xl m-3">Search Engine Optimization</h2>                            
            </div>
            <div class="service">                
                <img loading="lazy" src="../assets/images/mobile_application_development.svg" alt="" class="w-full">
                <h2 class="font-semibold text-xl m-3">Mobile Application Development</h2>                            
            </div>            
        </div>              
</div>
        <div class="bg-white justify-center"> 
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250"><path fill="#EFF6FF" fill-opacity="1" d="M0,64L10.4,90.7C20.9,117,42,171,63,176C83.5,181,104,139,125,144C146.1,149,167,203,188,224C208.7,245,230,235,250,208C271.3,181,292,139,313,106.7C333.9,75,355,53,376,42.7C396.5,32,417,32,438,32C459.1,32,480,32,501,58.7C521.7,85,543,139,563,154.7C584.3,171,605,149,626,149.3C647,149,668,171,689,165.3C709.6,160,730,128,751,112C772.2,96,793,96,814,128C834.8,160,856,224,877,229.3C897.4,235,918,181,939,176C960,171,981,213,1002,197.3C1022.6,181,1043,107,1064,90.7C1085.2,75,1106,117,1127,112C1147.8,107,1169,53,1190,53.3C1210.4,53,1231,107,1252,133.3C1273,160,1294,160,1315,133.3C1335.7,107,1357,53,1377,64C1398.3,75,1419,149,1430,186.7L1440,224L1440,0L1429.6,0C1419.1,0,1398,0,1377,0C1356.5,0,1336,0,1315,0C1293.9,0,1273,0,1252,0C1231.3,0,1210,0,1190,0C1168.7,0,1148,0,1127,0C1106.1,0,1085,0,1064,0C1043.5,0,1023,0,1002,0C980.9,0,960,0,939,0C918.3,0,897,0,877,0C855.7,0,835,0,814,0C793,0,772,0,751,0C730.4,0,710,0,689,0C667.8,0,647,0,626,0C605.2,0,584,0,563,0C542.6,0,522,0,501,0C480,0,459,0,438,0C417.4,0,397,0,376,0C354.8,0,334,0,313,0C292.2,0,271,0,250,0C229.6,0,209,0,188,0C167,0,146,0,125,0C104.3,0,83,0,63,0C41.7,0,21,0,10,0L0,0Z"></path></svg>   
            <h1 class="text-center text-4xl font-semibold text-primary tracking-widest uppercase">Our Story</h1>                                               
        </div>
        <div class="bg-white">
        <div class="container m-auto bg-white grid lg:grid-cols-12 gap-10 items-center lg:items-start">
            <div class="col-span-6 lg:col-span-12 xl:col-span-6 m-auto w-auto">
                <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_jvkbug4h.json"  background="transparent"  speed="0.3"  style="width: auto;"  loop  autoplay></lottie-player>
                <!-- <img loading="lazy" src="../assets/images/our_story.svg" alt="" class="object-cover"> -->
            </div>
            <div class="col-span-6 lg:col-span-12 xl:col-span-6 xl:-ml-20 text-lg font-medium text-justify leading-7 mt-20">
                <p class="p-10 py-5">
                    We are a boutique software development firm that provides cutting edge web development, design and SEO services, helping businesses untangle complex issues that always emerge during their digital evolution journey.
                </p>
                <div class="flex justify-end mr-10">
                    <div @click="toggleshowText">                        
                        <span class="p-1 py-3 rounded-full cursor-pointer animate__animated animate__fadeIn" v-if="!showText"><svg class="w-10 h-10 inline-block my-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></span>
                    </div>
                </div>
                <p v-if="showText" class="p-10 py-5 animate__animated animate__fadeIn">
                    The profound software engineering expertise, coupled with top-notch management, allow us to deliver a long list of our customers' services. No matter what technology you need our assistance on, we have a pool of extremely skilled and experienced developers and designers who possess all the major technologies' expertise.
                </p>
                <p v-if="showText" class="p-10 py-5 animate__animated animate__fadeIn">
                    Our development experts will provide you with the best resources, expertise, and technical assistance to cut additional costs, increase the speed of delivery, and help you solely focus on your business objectives.
                </p>  
                <div class="flex justify-end mr-10">
                    <div @click="toggleshowText">
                        <span class="p-1 py-3 rounded-full cursor-pointer animate__animated animate__fadeIn" v-if="showText"><svg class="w-10 h-10 inline-block my-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg></span>                  
                    </div>
                </div>
                <div class="justify-center">
                    <div class="text-xl text-center font-medium flex justify-center text-white">
                        <router-link to="/contact" onclick="window.scrollTo(0, 0);"><button class="btn-grad w-auto mb-10 block items-center justify-center uppercase rounded-2xl font-semibold text-white px-10 py-2 hover:shadow-md tracking-widest">Hire us</button></router-link>                                                                                 
                    </div>                                                                             
                </div>                          
            </div>
        </div>
        </div>
        <div class="bg-blue-50 md:m-auto justify-center py-20">               
            <h1 class="text-center text-4xl font-semibold text-primary tracking-widest uppercase">Trusted By The Leading Brands</h1>                                             
        </div>
        <div class="bg-blue-50"> 
            <div class="container m-auto bg-blue-50 row grid-row-1 pb-20">
                <div class="grid grid-cols-12 md:mx-4 lg:mx-20 xl:mx-36 opacity-30">
                    <div class="client">
                        <img loading="lazy" src="../assets/images/fastenal-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/lavazza-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/lacoste-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/coinfalcon-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/matras-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/planetmg-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/lindsay-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/baza-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/origins-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/brunswick-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/schmidt-logo.svg" alt="" class="w-auto lg:px-10">                                           
                    </div>
                    <div class="client">
                        <img loading="lazy" src="../assets/images/carlton-logo.svg" alt="" class="w-auto lg:px-10">                                        
                    </div>
                </div>
            </div>   
        </div> 

    <div class="bg-white">
      <div class="container m-auto">
        <div class="md:m-auto justify-center py-20">    
        <h1 class="text-center text-4xl font-semibold text-primary tracking-widest uppercase">Get Started Today</h1>   
        <p class="text-center text-2xl font-medium py-8 px-4">We take time to understand your business and customers, and then align our services to deliver remarkable results.</p> 
        <div class="grid m-auto">
          <span class="text-center uppercase bg-blue-50 rounded-xl p-4 px-6 m-auto w-auto text-primary font-semibold tracking-widest cursor-pointer"><a href="mailto:hello@qualon.xyz">hello@qualon.xyz</a></span>
        </div>                    
      </div>               
      </div>
    </div> 
      
        
    </main>
</template>

<script>
export default {
    data() {
        return {
            showText: false,
        }
    },
    methods: {
        toggleshowText(){
            this.showText = !this.showText
        }
    }
}
</script>